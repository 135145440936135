<template>
  <div>
    <LoadingBanner
      v-bind:title="'Invite New User'" 
      v-bind:isLoading="isLoading"></LoadingBanner>

    <div class="alert alert-danger" role="alert" v-if="errors.length > 0">
      There were errors on the page:
      <ul class="[ m-0 ]">
        <li v-for="(error) in errors" v-bind:key="error.id">
          {{ error.value }}
        </li>
      </ul>
    </div>
    
    <div class="row mt-3">
      <div class="col-md-12">
         <form class="form-horizontal">
          <div class="form-group">
            <label class="col-md-12 control-label" for="Register">Email</label>
            <div class="col-md-12">
                <input id="Register" v-model="user.email" name="Register" type="text" class="form-control input-md">
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-12 control-label" for="firstName">First Name</label>
            <div class="col-md-12">
                <input id="firstName" v-model="user.firstName" name="firstName" type="text" class="form-control input-md">
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-12 control-label" for="lastName">Last Name</label>
            <div class="col-md-12">
                <input id="lastName" v-model="user.lastName" name="lastName" type="text" class="form-control input-md">
            </div>
          </div>
          <div class="form-group">
            <div class="col-md-12 text-right">
              <a v-if="!isLoading" id="submit" href="#" class="btn btn-primary" v-on:click="sendEmail">Send Email Invite</a>
              <LoadingPanel v-else></LoadingPanel>
            </div>
          </div>
        </form>
      </div>
    </div>

    <MessageDisplay
      v-bind:errorMessage="errorMessage"
      v-bind:successMessage="successMessage"></MessageDisplay>
  </div>
</template>

<script>
import MessageDisplay from '@/components/MessageDisplay'
import LoadingPanel from "@/components/LoadingPanel";
import LoadingBanner from '@/components/LoadingBanner'
import { default as usersAPI } from '@/api/UsersAPI'
import { setSuccessMessage, setErrorMessage } from '@/utils'

export default {
  name: 'UserInvitesContainer',

  components: {
    'MessageDisplay': MessageDisplay,
    'LoadingBanner': LoadingBanner,
    'LoadingPanel': LoadingPanel
  },
  
  data () {
    return {
      isLoading: false,
      errorMessage: null,
      successMessage: null,
      user: {},
      errors: []
    }
  },

  methods: {
    sendEmail: function() {
      this.isLoading = true;
      this.errors = []
      usersAPI
        .sendInvite(this.user)
        .then(() => setSuccessMessage(this, 'successMessage'))
        .catch((response) => this.errors = response?.body?.errors)
        .finally(() => this.isLoading = false)
    }
  }
}
</script>

<style>
  .email-preview p {
    margin: 0;
    padding: 0;
  }
</style>